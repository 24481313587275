import { Text, Title } from "@clipboard-health/ui-react";
import { Image } from "@clipboard-health/ui-react";
import { IonContent, IonModal } from "@ionic/react";
import { Box, Button, Link } from "@mui/material";
import { useSession } from "@src/app/store/helperHooks";
import { logout, reloadAgentProfile } from "@src/app/store/session";
import { CbhFeatureFlag, useCbhFlags } from "@src/appV2/FeatureFlags";
import { logEvent } from "@src/appV2/lib/analytics";
import { SupportArticles, SupportContext } from "@src/appV2/support/constants";
import { useSupportLinks } from "@src/appV2/support/hooks/useSupportLinks";
import { useGetWorker } from "@src/appV2/Worker/api/useGetWorker";
import { USER_EVENTS } from "@src/constants";
import { getFirebaseSingleton } from "@src/lib/firebase/src";
import { useState } from "react";
import { useDispatch } from "react-redux";

export function AgentProfileErrorModal() {
  const { isFetchAgentProfileFailureAlertEnabled } = useSession();
  const {
    [CbhFeatureFlag.SHOW_ONBOARDING_AGENT_PROFILE_ERROR_MODAL]:
      shouldShowOnboardingAgentProfileError,
  } = useCbhFlags();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  // disabling the query since we want to only listen for changes here, without fetching
  const { data: worker } = useGetWorker({ enabled: false });
  const isOpen = Boolean(
    getFirebaseSingleton().currentUser?.email &&
      isFetchAgentProfileFailureAlertEnabled &&
      !worker?.userId
  );

  const { howToContactSupportLink } = useSupportLinks();

  return (
    <IonModal
      isOpen={shouldShowOnboardingAgentProfileError && isOpen}
      swipeToClose={false}
      onDidPresent={() => {
        logEvent(USER_EVENTS.ONBOARDING_WORKER_PROFILE_FETCHING_ERROR, {
          email: getFirebaseSingleton().currentUser?.email,
        });
      }}
    >
      <IonContent className="ion-padding">
        <Box
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Image
            alt="ClipboardHealth logo"
            src="/assets/logo/logo-horizontal.svg"
            sx={{
              height: 100,
            }}
          />
          <Title marginTop={4} bold component="h3">
            Oops, Something went wrong.
          </Title>
          <Text marginTop={1}>We were unable to load your profile.</Text>
          <Text>
            Please check your internet connection and try again. If this persists reach out to our
            support team.
          </Text>

          {howToContactSupportLink && (
            <Link
              href={howToContactSupportLink}
              target="_blank"
              onClick={() => {
                logEvent(USER_EVENTS.OPENED_SUPPORT_ARTICLE, {
                  articleLink: howToContactSupportLink,
                  articleName: SupportArticles.HOW_TO_CONTACT_SUPPORT,
                  supportContext: SupportContext.AGENTPROFILE_FETCH_ERROR,
                });
              }}
            >
              Contact Our 24/7 Support Team
            </Link>
          )}

          <Button
            variant="contained"
            disabled={isLoading}
            onClick={async () => {
              setIsLoading(true);
              await dispatch(reloadAgentProfile());
              setIsLoading(false);
            }}
            sx={{ marginTop: 3 }}
          >
            Try again
          </Button>
          <Button variant="text" onClick={() => dispatch(logout())}>
            <b>or Logout</b>
          </Button>
        </Box>
      </IonContent>
    </IonModal>
  );
}
