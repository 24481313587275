import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonHeader,
  IonIcon,
  IonRow,
  IonToolbar,
} from "@ionic/react";
import { FC, FormEvent } from "react";
import { useHistory } from "react-router-dom";

import { OnBoardingComponentProps } from "./model";
import { StyledIonPage } from "./style";
import { customCheckmark } from "../../../icons/customCheckmark";
import { ONBOARDING_SEGMENT_EVENT_NAMES } from "../constants/ONBOARDING_SEGMENT_EVENT_NAMES";
import { fireOnboardingSegmentEvent } from "../util/segment";

const ContractorAgreementReview: FC<OnBoardingComponentProps> = ({ agent, nextStagePath }) => {
  const history = useHistory();

  const onNext = async (event?: FormEvent | MouseEvent) => {
    event?.preventDefault();
    fireOnboardingSegmentEvent(ONBOARDING_SEGMENT_EVENT_NAMES.VIEWED_CONTRACTOR_BENEFITS, {
      hcpId: agent?.userId as string,
      email: agent?.email as string,
    });
    history.push(`${nextStagePath}`);
  };

  return (
    <StyledIonPage className="onboarding-page">
      <IonHeader no-border className="onboarding-header">
        <IonToolbar className="onboarding-toolbar">
          <IonButtons slot="start">
            <IonBackButton text="" defaultHref="/home/agentAddress" mode="ios" color="dark" />
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className="ion-padding">
        <div className="signup-content content-layout">
          <div className="form-container">
            <IonRow>
              <IonCol sizeMd="8" offsetMd="2" offsetLg="4" sizeLg="4">
                <div className="form-heading">
                  <h4>Using the Clipboard Health Marketplace</h4>
                  <p>
                    Here are some benefits of using our marketplace as an independent contractor
                  </p>
                </div>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <ul className="cbh-welcome-text infosteps">
                  <li>
                    <span>
                      <IonIcon icon={customCheckmark} slot="end" mode="ios" color="medium" />
                    </span>
                    <h4>Control Your Schedule</h4>
                    <p>We never assign or require you to take a shift</p>
                  </li>
                  <li>
                    <span>
                      <IonIcon icon={customCheckmark} slot="end" mode="ios" color="medium" />
                    </span>
                    <h4>Flexible Earnings</h4>
                    <p>
                      Book as many shifts as you want (or none), and take advantage of great hourly
                      rates
                    </p>
                  </li>
                  <li>
                    <span>
                      <IonIcon icon={customCheckmark} slot="end" mode="ios" color="medium" />
                    </span>
                    <h4>Fast Payment</h4>
                    <p>
                      You don't need to wait weeks to get paid. All debit cards and most bank
                      account types are eligible for instant payouts
                    </p>
                  </li>
                </ul>
              </IonCol>
            </IonRow>
          </div>

          <div className="signupform-footer footer-container">
            <form onSubmit={onNext}>
              <IonButton
                expand="block"
                size="large"
                className="ion-text-wrap ion-margin-top ion-margin-bottom continue-button"
                onClick={onNext}
              >
                Review the Independent Contractor Agreement
              </IonButton>
            </form>
          </div>
        </div>
      </IonContent>
    </StyledIonPage>
  );
};

export { ContractorAgreementReview };
