import { CameraSource } from "@capacitor/camera";
import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { IonButton, IonCard, IonIcon, IonSpinner, IonText, isPlatform } from "@ionic/react";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Box, Button, ClickAwayListener, IconButton, Stack, Tooltip } from "@mui/material";
import { useCapturePhoto } from "@src/app/utils/mediaUpload";
import { CbhFeatureFlag, useCbhFlag, useCbhFlags } from "@src/appV2/FeatureFlags";
import { DocumentVerificationProcessVariants } from "@src/appV2/FeatureFlags/CbhFeatureFlags";
import { MuiMarkdown } from "@src/appV2/lib";
import {
  DRIVING_LICENSE_STATE_ID_REQUIREMENT_ID,
  PERSONAL_ID_CUSTOM_DESCRIPTION,
  STRIPE_IDENTIFY_SDK,
} from "@src/constants/stripeDocumentAutoVerification.constants";
import { camera, cloudUpload, openOutline } from "ionicons/icons";
import { FC, Fragment, useMemo, useRef, useState } from "react";

import { AgentUploadDocumentProps } from "./model";
import { PersonalIdSelect } from "./PersonalIdSelect";
import { isImage } from "../../utils/isImage";
import { ONBOARDING_SEGMENT_EVENT_NAMES } from "../constants/ONBOARDING_SEGMENT_EVENT_NAMES";
import { ONBOARDING_STAGES } from "../constants/ONBOARDING_STAGES";
import { extractMarkdownLink } from "../util/extractMarkdownLink";
import { logOnboardingError } from "../util/logging";
import { fireOnboardingSegmentEvent } from "../util/segment";

const AgentDocumentUploader: FC<AgentUploadDocumentProps> = ({
  agent,
  document,
  uploading,
  uploaded,
  selectedFiles = [],
  setSelectedFiles,
  clearSelectedFiles,
  handleFileUpload,
  handleStripeUpload,
  stripeLoading,
}) => {
  const inputFileRef = useRef<HTMLInputElement>(null);
  const ldFlags = useCbhFlags();

  const cameraImageQuality = useCbhFlag(CbhFeatureFlag.CAMERA_IMAGE_QUALITY, {
    defaultValue: 99,
  });

  const cameraImageSize = useCbhFlag(CbhFeatureFlag.CAMERA_IMAGE_SIZE, {
    defaultValue: {},
  });

  const selectedFilesExist = selectedFiles?.length > 0;
  const canUploadMultiplePages = isImage(selectedFiles[0]?.type);

  const docVerificationProcess = useCbhFlag(CbhFeatureFlag.DOCUMENT_VERIFICATION_PROCESS, {
    defaultValue: DocumentVerificationProcessVariants.INTERNAL,
  });

  const [selectedPersonalIdOption, setSelectedPersonalIdOption] = useState("");
  const [requirementsTooltipOpen, setRequirementsTooltipOpen] = useState(false);

  const personalVerificationDocumentStripeOption: string[] | undefined =
    ldFlags[CbhFeatureFlag.PERSONAL_ID_SUBTYPES]?.stripe;
  const isPersonalIdDocument = document.reqId === DRIVING_LICENSE_STATE_ID_REQUIREMENT_ID;
  const stripeAutoVerifyEnabled =
    docVerificationProcess === STRIPE_IDENTIFY_SDK && isPersonalIdDocument;
  const { capturePhoto } = useCapturePhoto({
    onCaptured: (capturedFiles) => {
      if (capturedFiles) {
        const { file, fileBlob, type } = capturedFiles;
        setSelectedFiles({ file, fileBlob, type });
      }
    },
    onError: (error) => {
      if (error) {
        logOnboardingError(ONBOARDING_STAGES.DOCUMENTS, error?.message, agent?.userId);
      }
    },
  });
  const getFileFromSystem = (event) => {
    event.preventDefault();
    if (!event.target.files) {
      return;
    }

    const reader = new FileReader();
    const {
      target: {
        files: [file],
      },
    } = event;
    const [, type] = file.type.split("/");

    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setSelectedFiles({
        file: reader.result,
        fileBlob: file,
        type,
      });
    };
    event.target.value = null;
  };

  const openGallery = () => {
    capturePhoto(cameraImageQuality || 85, CameraSource.Prompt, cameraImageSize);
  };

  const handleTakePhotoClick = () => {
    fireOnboardingSegmentEvent(
      ONBOARDING_SEGMENT_EVENT_NAMES.ATTEMPTED_TO_ADD_DOCUMENT_DURING_SIGNUP,
      {
        hcpId: agent.userId as string,
        preference_distance: agent?.preference?.distance,
        requirementId: document._id,
      }
    );
    const isNative = isPlatform("capacitor");
    return isNative ? openGallery() : inputFileRef?.current?.click();
  };
  const documentDescription = isPersonalIdDocument
    ? PERSONAL_ID_CUSTOM_DESCRIPTION
    : `Add a photo of your ${document.name}`;

  const StripeButton = () => (
    <IonButton
      color="primary"
      mode="ios"
      disabled={stripeLoading || !selectedPersonalIdOption}
      onClick={() => {
        handleStripeUpload({ ...document, subType: selectedPersonalIdOption });
      }}
    >
      {stripeLoading ? (
        <IonSpinner slot="start" name="crescent" />
      ) : (
        <>
          <IonIcon slot="start" icon={openOutline} mode="ios" />
          <IonText color="light">{"Verify ID with Stripe"}</IonText>
        </>
      )}
    </IonButton>
  );

  const AddPhotoButton = () => (
    <>
      {!selectedFilesExist && (
        <IonButton
          color="primary"
          mode="ios"
          disabled={isPersonalIdDocument && !selectedPersonalIdOption}
          onClick={handleTakePhotoClick}
        >
          <IonIcon slot="start" mode="ios" icon={camera}></IonIcon>
          <IonText color="light">{"Add Photo"}</IonText>
        </IonButton>
      )}

      {!uploading && selectedFilesExist && !canUploadMultiplePages && (
        <IonButton
          expand="block"
          color="primary"
          disabled={uploading}
          onClick={() => {
            handleFileUpload({
              ...document,
              subType: isPersonalIdDocument ? selectedPersonalIdOption : "",
            });
          }}
        >
          <IonIcon slot="start" icon={uploaded ? camera : cloudUpload} mode="ios" />
          Upload
        </IonButton>
      )}

      {!uploading && selectedFilesExist && canUploadMultiplePages && (
        <div className="ion-text-center ion-margin">
          <IonButton onClick={handleTakePhotoClick}>
            <IonIcon slot="start" icon={camera} mode="ios" />
            Add more pages
          </IonButton>

          <IonButton
            onClick={() => {
              handleFileUpload({
                ...document,
                subType: isPersonalIdDocument ? selectedPersonalIdOption : "",
              });
            }}
          >
            <IonIcon slot="start" icon={cloudUpload} mode="ios" />
            Finish and upload
          </IonButton>
        </div>
      )}

      {!uploading && selectedFilesExist && (
        <IonButton color="light" mode="ios" onClick={clearSelectedFiles}>
          <span
            style={{
              color: "#727272",
              borderBottom: "1px solid #727272",
              paddingBottom: "3px",
              fontSize: "1rem",
            }}
          >
            Delete {selectedFiles.length > 1 ? "Files" : "File"}
          </span>
        </IonButton>
      )}
    </>
  );

  const extractedInstructionLink = useMemo(() => {
    if (isDefined(document.instructions) && document.instructions !== "") {
      return extractMarkdownLink(document.instructions);
    }
    return undefined;
  }, [document.instructions]);

  return (
    <Stack direction="column" spacing={3} sx={{ padding: 2.5 }}>
      <Stack direction="column" spacing={1}>
        <Stack direction="row" spacing={0.5} alignItems="center">
          <Text>REQUIREMENTS</Text>

          {isDefined(document.description) &&
            document.description !== "" &&
            document.disableWorkerUpload && (
              <ClickAwayListener onClickAway={() => setRequirementsTooltipOpen(false)}>
                <Tooltip
                  PopperProps={{
                    modifiers: [
                      {
                        name: "offset",
                        options: {
                          offset: [15, -10],
                        },
                      },
                    ],
                  }}
                  open={requirementsTooltipOpen}
                  title={document.description}
                >
                  <IconButton sx={{ padding: 0 }} onClick={() => setRequirementsTooltipOpen(true)}>
                    <HelpOutlineIcon sx={{ fontSize: 18 }} />
                  </IconButton>
                </Tooltip>
              </ClickAwayListener>
            )}
        </Stack>

        {document.disableWorkerUpload &&
        isDefined(document.instructions) &&
        document.instructions !== "" ? (
          <Box
            sx={() => ({
              color: "#828282",
            })}
          >
            {/* The instructions may contain a link in markdown format that should be rendered as a link */}
            <MuiMarkdown variant="body1">{document.instructions}</MuiMarkdown>
          </Box>
        ) : (
          <Text
            variant="body1"
            sx={{
              color: "#828282",
            }}
          >
            {documentDescription}
          </Text>
        )}

        {isPersonalIdDocument && !uploaded && (
          <PersonalIdSelect
            selectedOption={selectedPersonalIdOption}
            onChange={setSelectedPersonalIdOption}
          ></PersonalIdSelect>
        )}

        {selectedFilesExist && (
          <Fragment>
            {selectedFiles.map((selectedFile) => (
              <IonCard key={selectedFile.file} className="uxcam-occlude">
                {isImage(selectedFile.type) ? (
                  <img alt="timecard" src={selectedFile.file} />
                ) : (
                  <Box
                    sx={{
                      paddingY: 4,
                      background: "#f1efef",
                      textAlign: "center",
                    }}
                  >
                    {selectedFile.type ? selectedFile.type.toUpperCase() : "Document"}
                  </Box>
                )}
              </IonCard>
            ))}
          </Fragment>
        )}

        {uploading && (
          <IonButton expand="block" color="primary" disabled={true}>
            <IonSpinner slot="start" name="crescent" />
            Uploading
          </IonButton>
        )}
      </Stack>

      {!uploaded && document.disableWorkerUpload && isDefined(extractedInstructionLink) && (
        <Button onClick={() => window.open(extractedInstructionLink, "_blank")} variant="contained">
          View Requirement
        </Button>
      )}

      {!uploaded && !document.disableWorkerUpload && (
        <Fragment>
          {stripeAutoVerifyEnabled &&
          personalVerificationDocumentStripeOption?.includes(selectedPersonalIdOption || "") ? (
            <StripeButton />
          ) : (
            <AddPhotoButton />
          )}
        </Fragment>
      )}

      <input type="file" hidden onChange={getFileFromSystem} ref={inputFileRef} />
    </Stack>
  );
};

export { AgentDocumentUploader };
