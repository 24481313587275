import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";
import { FC } from "react";

const PersonalIdSelect: FC<{
  selectedOption: string;
  onChange: (value: string) => void;
}> = ({ selectedOption, onChange, ...props }) => {
  const { manual: manualOptions = [], stripe: stripeOptions = [] } = useCbhFlag(
    CbhFeatureFlag.PERSONAL_ID_SUBTYPES,
    {
      defaultValue: { manual: [], stripe: [] },
    }
  );

  return (
    <FormControl fullWidth>
      <InputLabel id="document-type">Document type</InputLabel>
      <Select
        fullWidth
        variant="standard"
        labelId="document-type"
        label="Document type"
        value={selectedOption}
        onChange={(event) => {
          onChange(event.target.value);
        }}
      >
        {[...stripeOptions, ...manualOptions].map((documentOption, index) => {
          // separate stripe options from manual, if both are available
          const showDivider = index === stripeOptions.length - 1 && manualOptions.length > 0;

          return (
            <MenuItem key={documentOption} value={documentOption} divider={showDivider}>
              {documentOption}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export { PersonalIdSelect };
